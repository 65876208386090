/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { array, boolean, mixed, object, string } from "yup";
import Button from "@components/Button";
import CheckBox from "@components/Form/CheckBox";
import CoverImage from "@components/Form/CoverImage";
/* eslint-disable jsx-a11y/control-has-associated-label */
import Form from "@components/Form/Form";
import Input from "@components/Form/Input";
import React, { useRef, useState } from "react";
import Switch from "@components/Form/Switch";
import TextArea from "@components/Form/TextArea";
import axios from "axios";
import { createToast } from "@helpers/createToast";
import mime from "mime-types";
import useAppDispatch from "@hooks/useAppDispatch";
import { useGetPostsTags, useCreatePostTag } from "@redux/tags/api";
import Select from "@components/Form/Select";
import {
  useCreatePost,
  usePutPost,
  useGetAdminFeaturedPost,
  useToogleFeaturedPost,
  IPost,
  postsApi
} from "@redux/posts/api";
import { PostFormData } from "@views/Admin/Posts/ViewPostForm";
import { Modal } from "react-bootstrap";
import MaterialIcon from "@components/MaterialIcon";
import { Link, useNavigate } from "react-router-dom";
import RBForm from "react-bootstrap/Form";
import get from "lodash/get";
import { restartDraftPost, setDraftPost } from "@redux/posts/slice";
import { colors } from "@theme/colors";
import styled from "styled-components";
import ButtonGroup from "@components/ButtonGroup";

const postSchema = object({
  name: string()
    .required("Title is required")
    .min(5, "Title must be at least 5 characters long")
    .max(255, "Title can be no longer than 255 characters"),
  subtitle: string()
    .required("Sub title is required")
    .min(2, "Sub title must be at least 2 characters long")
    .max(255, "Sub title can be no longer than 255 characters"),
  post_tags: array().of(string()),
  content: string().required("Content is required"),
  coverImage: mixed().required("Cover Image is a required field"),
  publish: boolean(),
  featured: boolean(),
});

interface PostData {
  [key: string]: any;
  coverImage: File;
}

const CustomCheck = styled(RBForm.Check)`
  .form-check-input:checked  {
    background-color: ${colors.brandColorPrimary};
    border-color: ${colors.brandColorPrimary};
  }
  .form-check-input:focus {
    box-shadow: none;
    border-color: ${colors.brandColorPrimary};
  }
`

const PostForm: React.FC<{ initialValues: IPost | PostFormData }> = ({
  initialValues,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: postsTags } = useGetPostsTags();
  const { data: featuredPost } = useGetAdminFeaturedPost();
  const [formData, setFormData] = useState<PostData | null>(null);
  const [toogleFeaturedPost, { isLoading: isLoadingToogleFeaturedPost }] = useToogleFeaturedPost();
  const [showReplaceFeaturedPostModal, setShowReplaceFeaturedPostModal] =
    useState(false);
  const [postToReplace, setPostToReplace] = useState<any>(null);
  const [createPostTag] = useCreatePostTag();
  const [createPost, { isLoading: isLoadingPost }] = useCreatePost();
  const [putPost, { isLoading: isLoadingPut }] = usePutPost();
  const [isUploadingCoverImage, setIsUploadingCoverImage] = useState(false);
  const submitForm = initialValues.id ? putPost : createPost;

  const submitCallbackRef = useRef({
    newPostId: null,
    pendingCallback: () => { }
  })

  const onSubmit = async (data: PostData) => {
    const fileExtension =
      data.coverImage.name.split(".")[
      data.coverImage.name.split(".").length - 1
      ];
    const mimeType = mime.lookup(fileExtension) || "";
    const dataToSend = { ...data };

    if (
      initialValues.file &&
      data.coverImage.name === initialValues.file.name
    ) {
      dataToSend.file_id = initialValues.file.id;
      delete dataToSend.file;
    } else {
      dataToSend.file = {
        original_name: data.coverImage.name
          .split(".")
          .slice(0, data.coverImage.name.split(".").length - 1)
          .join("."),
        mime_type: mimeType,
        extension: fileExtension,
        size: data.coverImage.size,
      };
    }
    try {
      const responseData: any = await submitForm(dataToSend).unwrap();
      if (!dataToSend.file_id) {
        setIsUploadingCoverImage(true)
        await axios.put(
          responseData.data.file.temporary_upload_url,
          data.coverImage,
          {
            headers: {
              "Content-Type": mimeType,
            },
          },
        );
      }

      submitCallbackRef.current.newPostId = responseData.data.id;
      setIsUploadingCoverImage(false)
      createToast("The post was created successfully", "success", dispatch);
      submitCallbackRef.current.pendingCallback()
    } catch (err) {
      console.log({ err })
      const isFeaturedError = get(err, "data.errors.is_featured");
      if (isFeaturedError) {
        setFormData(data);
        setShowReplaceFeaturedPostModal(true);
        return;
      }
      createToast("We have an error creating the post", "danger", dispatch);
    }
  };


  const replaceFeaturedPost = () => {
    if (postToReplace) {
      setShowReplaceFeaturedPostModal(false)
      toogleFeaturedPost({
        id: postToReplace.id,
        is_featured: false,
      })
        .unwrap()
        .then(() => {
          if (formData) {
            onSubmit(formData);
          }
        });
    }
  };


  const saveAndPreview = (data: IPost) => {
    const saveData = { ...data };
    const tagsWithName = postsTags.data.filter((tag: any) => data.post_tags.includes(tag.id))
    saveData.post_tags = tagsWithName;
    if (saveData.coverImage && !saveData.coverImage.id) {
      const reader = new FileReader();
      saveData.fileName = saveData.coverImage.name;
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          const base64String = reader.result;
          saveData.coverImage = base64String;
          dispatch(setDraftPost({ data: saveData }));
          navigate("/admin/post/preview");
        }
      };
      if (saveData.coverImage instanceof File) {
        reader.readAsDataURL(saveData.coverImage);
      } else {
        console.error('coverImage is not a file');
      }
    } else {
      dispatch(setDraftPost({ data: saveData }));
      navigate("/admin/post/preview");
    }
  };



  return (
    <>
      <span className="o-cl-grey-100 o-ft-2xl-400">Post Content</span>
      <Modal show={showReplaceFeaturedPostModal} centered>
        <Modal.Header>
          <div className="d-flex align-items-center">
            <span className=" o-cl-grey-100 o-ft-3xl-600">
              Show as Featured Post
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              type="button"
              className="bg-transparent border-0 px-3"
              onClick={() => setShowReplaceFeaturedPostModal(false)}
            >
              <MaterialIcon icon="close" size={30} color="o-cl-grey-100" />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3 flex-column mx-4">
            <span className="o-ft-sm-400 o-cl-grey-100">
              Our featured posts section is limited to 3 entries. To add a new
              one, pick a post you&apos;d like to replace:
            </span>
            {featuredPost.data.map((post: IPost) => (
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <Link
                    to={`/admin/post/${post.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center gap-2 text-decoration-none o-cl-grey-100 o-ft-sm-700"
                  >
                    <MaterialIcon
                      icon="open_in_new"
                      size={18}
                      color="o-cl-grey-100"
                    />
                    {post.name}
                  </Link>
                </div>
                <div>
                  <CustomCheck
                    type="radio"
                    name="replace"
                    onClick={() => setPostToReplace(post)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            <Button
              value="Replace"
              type="button"
              size="medium"
              className="my-3"
              disabled={!postToReplace}
              onClick={replaceFeaturedPost}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Form
        onSubmit={onSubmit}
        defaultValues={initialValues}
        schema={postSchema}
      >
        {({ setValue, getValues, handleSubmit }) => (
          <>
            <Input
              name="name"
              label="Title"
              placeholder="Enter a title"
              isRequired
            />
            <Input
              name="subtitle"
              label="Subtitle"
              placeholder="Enter a subtitle"
            />
            <Select
              name="post_tags"
              label="Tags"
              placeholder="Select or create tags"
              options={
                postsTags
                  ? postsTags.data.map((tag: any) => ({
                    label: tag.name,
                    value: tag.id.toString(),
                  }))
                  : []
              }
              handleCreateTag={(value) =>
                createPostTag({ name: value }).then((newTag: any) => {
                  const { data } = newTag;
                  const postTags = getValues("post_tags");
                  setValue("post_tags", [...postTags, data.data.id]);
                  createToast("Tag add successfully!", "success", dispatch);
                })
              }
            />
            <TextArea name="content" label="Content" isRequired />
            <span className="o-cl-grey-100 o-ft-2xl-400 mt-4 d-block">
              Cover Image <span style={{ color: "red" }}>*</span>
            </span>
            <CoverImage name="coverImage" />
            <span className="o-cl-grey-100 o-ft-2xl-400 mt-4 d-block">
              Post Settings
            </span>
            <Switch
              name="is_published"
              label="Publish Post"
              helperText="*Post remains a draft unless published with the toggle"
            />
            <Switch
              name="is_featured"
              disabled={featuredPost.data.length < 3 && !initialValues.id}
              label="Show as Featured Post"
              helperText="*By default, the three most recent posts will be displayed in the Featured Posts section. These posts can be manually replaced at any time."
            />
            <CheckBox
              name="is_author_visible"
              label="Show author name"
              helperText="*Unchecking this box will hide the author's name from post information in both backend and frontend of the platform"
            />
            <div className="mt-4 d-flex justify-content-between">
              <Button
                onClick={() => saveAndPreview(getValues())}
                className="o-cl-brand-primary o-ft-xl-700 o-cursor-pointer text-decoration-none"
                value="Preview Post"
                variant="link"
              />
              <div className="d-flex mx-2 align-items-center">
                <a
                  href="/admin/post"
                  className="o-cl-grey-100 o-ft-xl-700 o-cursor-pointer text-decoration-none mx-5"
                >
                  Cancel
                </a>
                <ButtonGroup
                  isDisabled={isLoadingPost || isLoadingPut || isUploadingCoverImage || isLoadingToogleFeaturedPost}
                  isLoading={isLoadingPost || isLoadingPut || isUploadingCoverImage || isLoadingToogleFeaturedPost}
                  primaryAction={{
                    onClick: handleSubmit(async (data) => {
                      try {
                        submitCallbackRef.current.pendingCallback = () => {
                          dispatch(postsApi.util.invalidateTags(["Posts", "Featured_Admin_Posts"]));
                          dispatch(postsApi.util.resetApiState());
                          navigate(-1)
                        };
                        await onSubmit(data);
                      } catch (err) {
                        console.log(err);
                      }
                    }),
                    value: "Save and back",
                  }}
                  otherActions={[
                    {
                      onClick: handleSubmit(async (data) => {
                        try {
                          submitCallbackRef.current.pendingCallback = () => {
                            dispatch(postsApi.util.resetApiState());
                            dispatch(postsApi.util.invalidateTags(["Posts", "Featured_Admin_Posts"]));
                            dispatch(restartDraftPost());
                            if (!initialValues.id && submitCallbackRef.current.newPostId) {
                              navigate(`/admin/post/${submitCallbackRef.current.newPostId}/edit`, { replace: true });
                            }
                          }
                          await onSubmit(data);
                        } catch (err) {
                          console.log(err);
                        }
                      }),
                      value: "Save and edit this item",
                    },
                    {
                      onClick: handleSubmit(async (data) => {
                        try {
                          submitCallbackRef.current.pendingCallback = () => {
                            dispatch(postsApi.util.invalidateTags(["Posts", "Featured_Admin_Posts"]));
                            dispatch(restartDraftPost());
                            dispatch(postsApi.util.resetApiState());
                            navigate(`/admin/post/create`, { replace: true });
                          }
                          await onSubmit(data);
                        } catch (err) {
                          console.log(err);
                        }
                      }),
                      value: "Save and new item",
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default PostForm;
